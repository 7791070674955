import car_accident from '../assets/articles/car_accident.png'
import christmas_2020 from '../assets/articles/christmas_2020.jpg'
import honda_green from '../assets/articles/honda_green.jpg'
import hyundai_white from '../assets/articles/hyundai_white.jpg'
import insurances from '../assets/articles/insurances.png'
import kia_red from '../assets/articles/kia_red.jpg'
import kia_white from '../assets/articles/kia_white.jpg'
import lexus_grey from '../assets/articles/lexus_grey.jpg'
import mazda_blue from '../assets/articles/mazda_blue.jpg'
import new_partner from '../assets/articles/new_partner.jpg'
import nissan_white from '../assets/articles/nissan_white.jpg'
import raise from '../assets/articles/raise.png'
import seat_white from '../assets/articles/seat_white.jpg'
import support from '../assets/articles/support.jpg'
import suzuki_white from '../assets/articles/suzuki_white.jpg'
import team_ben from '../assets/articles/team_ben.png'
import team_pete from '../assets/articles/team_pete.png'
import tech from '../assets/articles/tech.jpg'
import telematics from '../assets/articles/telematics.jpg'
import tips from '../assets/articles/tips.jpg'
import top_10_2015 from '../assets/articles/top_10_2015.png'
import toyota_red from '../assets/articles/toyota_red.jpg'
import uninsured from '../assets/articles/uninsured.png'

export const information = {

    "christmas-2020": [
        {
            SEOTitle: `Christmas 2020 - opening times - United Legal Assistance`,
            SEODescription: `Christmas 2020 - opening times - United Legal Assistance`,
            title: 'Christmas 2020 – opening times',
            alignment: 'left',
            inline: true,
            image: christmas_2020,
            description: [
                {
                    text: 'We would like to thank everyone for their support shown throughout this unprecedented year and wish everyone a very happy and safe Christmas.'
                },
                {
                    text: 'Instead of Xmas cards or gifts his year we are instead sending a donation to our chosen charity CLIC Sargent. It’s been a tough year for the charity and with a shortfall of £9 million we hope our donation goes towards supporting young cancer patients and their families.'
                },
                {
                    text: 'Office opening hours over Xmas **(New claims line remains in operational 24 hours a day)**',
                },
                {
                    text: '**23rd Dec** 9:00am – 7:00pm',
                },
                {
                    text: '**24th Dec (Xmas eve)** 9:00am – 2:00pm',
                },
                {
                    text: '**25th – 28th Dec – Closed**',
                    highlight: true,
                },
                {
                    text: '**29th & 30th Dec** 9:00am – 5:30pm',
                },
                {
                    text: '**31st Dec (New Year’s Eve)** 9:00am – 2:00pm',
                },
                {
                    text: '**1st Jan – 3rd Jan – Closed**',
                    highlight: true,
                },
                {
                    text: '**4th Jan** 9:00am – 7:00pm',
                },
            ]
        }

    ],
    "new-partner": [
        {
            SEOTitle: `New Partner Announcement: Walmsleys Commercial Insurance Brokers - United Legal Assistance`,
            SEODescription: `New Partner Announcement: Walmsleys Commercial Insurance Brokers - United Legal Assistance`,
            title: 'New Partner Announcement: Walmsleys Commercial Insurance Brokers',
            alignment: 'left',
            inline: true,
            image: new_partner,
            description: [
                {
                    text: 'We are proud to announce that we have been appointed as claims management provider for one of the UK’s leading Chartered Insurance Brokers, Walmsleys Commercial. ULA will be providing a market-leading claims management service for all of Walmsleys’ clients, irrespective of fault.'
                },
                {
                    text: 'ULA will be offering a particular focus to the replacement vehicle policies on offer, ensuring Walmsleys’ commercial fleet clients receive a suitable vehicle for the needs of the business in the event of a fault accident. Working with Walmsleys’, ULA will be providing fleet risk management to Walmsleys Commercial’s fleet clients through various innovative technological solutions; further enhancing the incredibly successful Fleetcover scheme they currently have.'
                },
                {
                    text: 'Philip Wall, Managing Director at Walmsleys Commercial comments; “We are very pleased to be partnering with ULA, who have been appointed following a lengthy and in depth review of our motor claims offering. We have experienced significant growth in the motor sector in recent years, particularly under our Fleetcover and Garagecover brands and it’s important that we continue to differentiate our service offering from our competitors in today’s crowded marketplace. We are confident that ULA will allow us to do just that”.'
                },
                {
                    text: 'ULA’s Director and founder, Adam Hughes, comments; “We are delighted to announce this partnership and believe it is yet another sign that ULA work in the right way. We pride ourselves on being ahead of the market by pioneering new products and services, ensuring that as a result, all of our broker partners remain ahead of their competition – be it through a unique LEI policy or claims service. There is a great deal of opportunity to work creatively with Walmsleys and we are looking forward to providing a service their client’s would expect”.'
                },
                {
                    text: 'Walmsleys have had a presence in Wigan since 1926, and Walmsleys Commercial was formed in 2004 to concentrate exclusively on providing a specialist insurance service to commercial clients, both locally and throughout the UK. Since 2004 Walmsleys Commercial have invested heavily in new technology and quality, experienced personnel. This has helped them maintain and grow a sizeable, local and traditional client base, whilst continuing to innovate and create various brands that trade nationally in specific areas of trade and insurance cover.'
                },
                {
                    text: 'United Legal Assistance were established in 2014 and are owned and managed by brothers, Adam & Gavin Hughes. With over 20 years’ industry experience, they have grown the company from 3 staff to 30, whilst settling into their state of the art 2-floor offices in Southport. United Legal Assistance have become specialists in working with independent commercial insurance brokers, with their attention and understanding of commercial fleets, combined with innovative technology, cited as the main reason for brokers choosing ULA.'
                },
            ]
        }

    ],
    "tech": [
        {
            SEOTitle: `5 Tech Startups Disrupting The Insurance Industry`,
            SEODescription: `The rise of Insurance Technology has come as a surprise to those who view the industry of lacking forward thinking.`,
            title: '5 Technology Startups Disrupting the Insurance Industry',
            alignment: 'left',
            inline: false,
            image: tech,
            description: [
                {
                    text: 'The rise of Insurance Technology has come as a surprise to those who view the industry of lacking forward thinking. However, in recent years the growth in FinTech and InsurTech startups have provided new ways to tackle industry problems. With new customer demands and expectations, the need to innovate is being met by many startups who can leverage a range of current market trends quickly.'
                },
                {
                    text: 'With some promising contenders for 2016, here are some of the ones to watch:'
                },
                {
                    titleDes: '1. Cuvva',
                    text: 'Taking advantage of the growing need for short term car insurance, [Cuvva](https://www.cuvva.com/) has mobilised its unique offering where hourly car insurance can be purchased through its user-friendly app. Set up and based in Scotland, Cuvva tackles the historically labour intensive task of getting short term car insurance. The built-in capability allows quick cross checking of your DVLA records, while verifying your identity, which cuts out a lot of the legwork for the user. With seed funding from a Scottish angel syndicate, the future is looking bright for Cuvva.'
                },
                {
                    titleDes: '2. Cocoon',
                    text: 'This sophisticated home security device was developed by Sanjay Parekh, who wanted to give himself peace of mind whilst away on holiday. Simple to setup and easy to use, it is controlled by your phone and alerts the user of any unusual activity prompted by sound-based technology. The size of a tennis ball, it detects low-frequency sounds in order to detect movement. If an alarm is raised, the user can view 30 seconds of video, and alert the police if necessary afterwards. Zurich Insurance has teamed up with Cocoon, linking the value of insurance with that vital reassurance consumers are looking for. Giving quick and easy peace of mind is proving a popular solution when users are away, and having the technology to do so makes Cocoon one to watch over the coming years.'
                },
                {
                    titleDes: '3. Brolly',
                    text: 'Labelling itself an “insurance concierge”, Brolly is taking big steps in the world of artificial intelligence. Split into three products, Brolly Advisor, Brolly Locker and Brolly Shop; each one targets a distinct need and solution. Brolly Advisor can tell you whether you are over or under insured, and highlights where you may potentially be going wrong with your cover, based on your needs. Brolly Locker is a storage system for insurance documents, making them easy to access and find. The Brolly Shop will launch next year, acting as a price comparison tool for insurance, but updating automatically which will allow ease of reinsuring when renewal dates are approaching. Transforming insurance tasks which have been labour intensive and unorganised in the past simple, easy and user-friendly is at the heart of Brolly’s growing popularity and the potential for the future. Having a user experience with everything in one place is a unique and exciting proposition for the insurance market as it is.'
                },
                {
                    titleDes: '4. Spixii',
                    text: 'A chatbox which acts as an insurance advisor, [Spixii](https://www.spixii.com/) is enabling customers to get quick, easy and personal insurance advice. Converting the task of comparing insurance providers into a private conversation is making the user journey one of ease. A simple interaction without the form filling, the WhatsApp-like conversation uses AI technology and machine learning, and can even detect when you are abroad and might not be covered by your insurance for particular activities. The key to the success of Spixii will rest on its software quality, but the concept is one which gives an ever more personalised experience to the customer and is looking like a great contender in the Insurtech space.'
                },
                {
                    titleDes: '5. Fitsense',
                    text: 'With the health trend and use of wearables on the up, the [Fitsense](http://www.fitsense.io/) is adding value to the insurance market by using technology to help insurers understand their customers even better. Combining data from these health wearables with other device data allows insurers to create even richer customer profiles from which they can build insurance products and services to serve specific client needs. Combining the value of big data with insurance products is an invaluable opportunity for them to accelerate revenue streams and maximise the effectiveness of their product offers. The combination of three major market trends in big data, health and personalisation, is one that gives Fitsense a great chance at making a mark in the insurance sector.'
                },
            ]
        }

    ],
    "tips": [
        {
            SEOTitle: `10 Tips for Under 25s Car Insurance - United Legal Assistance`,
            SEODescription: `Finding affordable and comprehensive car insurance cover for young drivers is often a challenge. Here are 10 tips to help you out.`,
            title: '10 Tips for Under 25s Car Insurance',
            alignment: 'left',
            inline: false,
            image: tips,
            description: [
                {
                    text: 'Finding affordable and comprehensive car insurance cover for young drivers is often a challenge, especially after the hard work of passing your test and saving for your car.'
                },
                {
                    text: 'The average cost of car insurance for a 17-22-year-old currently stands at an extortionate £1,290 per year. With hundreds of providers, the task of finding the best deal can seem overwhelming.'
                },
                {
                    text: 'We’ve got some simple and easy car insurance tips to help you find the best deal.'
                },
                {
                    titleDes: '1) Consider how comprehensive the cover you need is.',
                },
                {
                    text: 'Compulsory Third Party (CTP) insurance is tempting when you are trying to save on your premium; however, this option ends up expensive once you consider the cover it provides. On top of this, some third party only insurance can be more expensive than the comprehensive cover, so it is worth shopping around.'
                },
                {
                    text: 'Although adding non-compulsory policies can seem expensive, the simple CTP insurance will not cover any vehicle/property damage, and only covers third party injury. However, once taking into account the value of your vehicle you should be able to consider the level of comprehensive cover you need.'
                },
                {
                    text: 'For example, for less expensive second-hand cars, it can be cheaper to add fire and theft cover with third party property insurance than going for a comprehensive policy.'
                },
                {
                    titleDes: '2) Consider adding a second driver.',
                },
                {
                    text: 'Adding a second driver to your policy can reduce the costs, even though this may not seem logical. If you are adding a driver who is a lower risk to the insurer than yourself, you can save a lot on your policy. In particular, drivers with a good history should reduce the cost substantially, as insurers are allowed to take driving history into account. Any second driver that you do add needs to be someone who would reasonably drive your car. Do not add any driver as the primary driver if they are not one as this counts as fraud, and you can face a criminal conviction for doing this.'
                },
                {
                    text: 'Adding a second driver to your policy can reduce the costs, even though this may not seem logical. If you are adding a driver who is a lower risk to the insurer than yourself, you can save a lot on your policy. In particular, drivers with a good history should reduce the cost substantially, as insurers are allowed to take driving history into account. Any second driver that you do add needs to be someone who would reasonably drive your car. Do not add any driver as the primary driver if they are not one as this counts as fraud, and you can face a criminal conviction for doing this.'
                },
                {
                    text: 'If you share vehicles between friends and family, insuring them together can save you money. You will need to compare the cost of insuring each car separately before deciding whether multi car insurance could be cheaper. Multi-vehicle discount insurers include Aviva, Churchill, Directline and Privilege, and you will need to get direct quotes from these providers.'
                },
                {
                    titleDes: '3) Minimise the risk to insurers',
                    text: 'Choosing a car that sits within a ‘low risk’ band will mean cheaper insurance premiums. However, adding simple features to any vehicle such as alarms and kill switches can dramatically affect your premium. Similar to this, if you store your vehicle off-road, either in a garage or driveway, you can save a lot of money. Even completing an additional safe driving course after your test can pay for itself in the long run by reducing the cost of your insurance. These are simple factors that can make a huge difference to money saving when quoting for insurance as a young and inexperienced driver.'
                },
                {
                    titleDes: '4) Black Boxes',
                    text: 'A [telematics device often referred to as a “black box”](/what-is-telematics-insurance) measures data while you drive, feeding back to your insurer and affecting your insurance costs. It measures your speed, braking speed, acceleration around corners and time of driving to determine your risk of an accident or damage. For safe and cautious drivers, installing a “black box” can single-handedly have the best impact on the cost of their insurance. However, the cost of insurance for young drivers is often a reasonable measure of their risk, so it is worth serious consideration before deciding on telematics. Poor driving will result in dramatically increased insurance premiums, and the black box can be more of a hindrance than a help when it comes to reducing your costs.'
                },
                {
                    titleDes: '5) Consider the periodicity of your payments',
                    text: 'This is the most simple tip of them all, and an easy way to knock money off a policy without having to change any of its features. Paying for a year up front is often cheaper than making regular payments, so if you have the money put aside for a year’s worth of insurance, it is worth paying in one go.'
                },
                {
                    titleDes: '6) Get the excess right',
                },
                {
                    text: 'Considering the excess that you require vital in making sure that you are paying the right amount- you need to make sure you are covering yourself to afford any potential damage, while not paying an enormous fee to cover all eventualities.'
                },
                {
                    text: 'Paying for a policy with a higher excess will reduce the cost of your premium, but if you need to claim you will need to pay a larger amount out of your pocket. On top of this, claiming for less than £500 worth of damage increases the cost of any future insurance policies, and removes a no-claims bonus that you may have. This makes any excess below the £500 damaging to your future insurance. However, if you need to cover yourself in the case of damage to make sure you can afford a claim, your premium will reflect this and likely be increased.'
                },
                {
                    text: 'Some companies such as [We Pay Any Excess](https://www.wepayanyexcess.com/) will charge a cost to pay out any excesses that you may have, whilst allowing a reduced premium by increasing your excess when you get a quote. For example, increasing your excess from £500 to £1000 and getting this excess payment covered can cost you as little as £80 for four years.'
                },
                {
                    titleDes: '7) Get a new quote- don’t auto renew',
                },
                {
                    text: 'Insurers make money out of customers who don’t bother to change their insurance provider. If you know that your renewal date is coming up, make sure that you take the time to get a new quote, as it is likely that switching to another provider will save you a lot of cash.'
                },
                {
                    text: 'Car insurance costs are typically increasing, so using insurers who provide quotes that can be locked in can be beneficial. Some insurers allow you to lock in a quote price as far in advance as 60 days before your renewal date, enabling you to avoid an increase in the quote if the price has gone up.'
                },
                {
                    titleDes: '8) Extend your search outside of comparison websites',
                    text: 'Some of the biggest and best insurers do not sit on comparison sites and are worth getting direct quotes from. Direct Line, Aviva and Zurich, for example, do not appear on comparison websites, so although they can be an easy way of saving time comparing quotes, it is worth looking outside of these if you want to get the best deal.'
                },
                {
                    titleDes: '9) Cashback Websites',
                    text: 'Although these websites do not directly affect the price of your car insurance quote, they are a useful way of getting money back into your pocket. These cashback sites have paid links from retailers/financial service providers, so if you click through they get paid for it. They then compensate you with some of this cash, so it can be an additional bonus if you find a quote and can get cashback once you purchase the insurance. It is not worth using these websites to narrow the pool of insurers you will get quotes from, as they may not get you the best price, but it can be an added benefit if you find your insurer sits on one of these cashback websites.'
                },
                {
                    titleDes: '10) Keep your details updated',
                    text: 'After the time and money invested in getting yourself a good deal on your car insurance, make sure you cover yourself by making sure that all your details are correct on your policy. If you need to make a claim and your insurer finds some of your details to have changed or to be incorrect, your claim may not be valid. Typical examples where customers forget to update their policy include a change of job or any modifications they make to their vehicle.'
                },
            ]
        }

    ],
    "team-pete": [
        {
            SEOTitle: `Meet The ULA Team: Pete Mitchell | ULA Blog`,
            SEODescription: `Next up in our Meet the ULA team series is Pete Mitchell. Pete is our Operations Manager, and here is what he gets up to.`,
            title: 'Meet the ULA Team: Pete Mitchell',
            alignment: 'left',
            inline: true,
            image: team_pete,
            description: [
                {
                    text: 'Next up in our Meet the ULA team series is Pete Mitchell. Pete is our Operations Manager, and here is what he gets up to…'
                },
                {
                    boldTitle: '**1. Tell us about yourself and what you do at United Legal Assistance?**',
                },
                {
                    text: 'Hey, my name is Pete, I am 25 and live in Southport. At United Legal Assistance I am in charge of managing our fleet of prestigious vehicles. Our fleet is offered to clients who have been involved in a non-fault incident on a credit hire basis. I handle the process from first notification through to the recovery.'
                },
                {
                    boldTitle: '**2. What has been your top moment at United Legal so far?**',
                },
                {
                    text: 'I have been involved from the start, so it has been great watching the team expand, go through two office moves and grow organically over the last few years.'
                },
                {
                    boldTitle: '**3. How long have you worked in the Insurance Industry for?**',
                },
                {
                    text: 'I have been in the insurance industry for almost seven years now.'
                },
                {
                    boldTitle: '**4. What is your favourite thing to do outside of work?**',
                },
                {
                    text: 'I am a Football fan and follow Liverpool FC, and I also enjoy playing football, and I am part of a five aside team. I also enjoy playing Darts.'
                },
                {
                    boldTitle: '**5. Tell us something that nobody in the office knows about you?**',
                },
                {
                    text: 'Well, back in School my dream job was to be a pilot – maybe one day.'
                },
                {
                    boldTitle: '**6. What is your dream car?**',
                },
                {
                    text: 'Jaguar E-Type of Ferrari Enzo'
                },
                {
                    boldTitle: '**7. If you were to host a party, which three celebrities would you invite and why?**',
                },
                {
                    text: 'Sean Lock – I am a big fan, and he is one of my favourite comedians.'
                },
                {
                    text: 'Jeremy Clarkson – Simply because I loved Top Gear, and for the controversy'
                },
                {
                    text: 'Ronnie O’Sullivan – Can’t forget Ronnie!'
                },
            ]
        }

    ],
    "uninsured": [
        {
            SEOTitle: `The Things You Need to Know About Uninsured Loss Recovery | ULA Blog`,
            SEODescription: `Here we explain what Uninsured Loss Recovery is, and the types of things you will be covered for in the event of an accident.`,
            title: 'The Things You Need to Know About Uninsured Loss Recovery!',
            alignment: 'left',
            inline: false,
            image: uninsured,
            description: [
                {
                    text: 'We all know why we take out insurance, it’s a sensible thing to do and in many cases, also a legal requirement. We pay for our policy and can then sleep at night knowing that should the worst, happen we are protected.',
                },
                {
                    text: 'The wake-up call can come when you realise that what you thought was full cover has in fact left some glaring holes that could result in you footing the bill.',
                },
                {
                    text: 'Which is why Uninsured Loss Recovery was introduced, but what is Uninsured Loss Recovery?',
                },
                {
                    text: 'If you’ve had an accident, then you might have some out of pocket expenses that aren’t covered by your insurance. These could be anything from lost earnings, phone calls you needed to make or more straightforward things like the cost of hiring a car or having to use public transport. Taken in isolation these costs may seem small or insignificant – what’s a phone call here and there? When you add up all of the potential ‘extras’ that standard cover would leave you having to pay out, though, and the costs can soon mount up.',
                },
                {
                    text: 'Assuming the other driver was at fault, Uninsured Loss Recovery (also referred to as Legal Expenses Insurance) would help you to recoup those costs and potentially also seek compensation if you were injured.',
                },
                {
                    titleDes: 'How does it work?',
                },
                {
                    text: 'Some policies will include uninsured loss recovery or have given an option to add it on when you took out your policy. The first step is to check your paperwork or contact your insurer to find out if you have it. If not, this is something you can purchase separately, even after an accident.',
                },
                {
                    boldTitle: '***Types of Things Uninsured Loss Recovery Can Cover:***',
                },
                {
                    list: 'Any excess you had to pay for repairs.'
                },
                {
                    list: 'Car hire charges, as long as these are reasonable. If you own an Aston Martin, you still need to justify hiring one whilst yours is in for repair. Likewise, if you own a Micra, then you shouldn’t hire a Mercedes.'
                },
                {
                    list: 'Clothing and personal items. Don’t throw away anything that was damaged until after your claim is settled; the items may need to be examined or evidenced. You should also look out any receipts of proof of purchase you might have for the items you will be claiming for.'
                },
                {
                    list: 'Towing and storage charges as long as the car is recovered in a reasonable time.'
                },
                {
                    list: 'Medical expenses and personal injury.'
                },
                {
                    list: 'Loss of earnings from missed work due to the accident.'
                },
                {
                    list: 'Legal fees incurred during the process.'
                },
                {
                    titleDes: 'How United Legal Assistance Can Help with Uninsured Loss',
                },
                {
                    text: 'We have a 24-hour claims number so you can report it as soon as you’ve had an accident. We understand that having an accident is stressful enough, so we look to recover your losses and get you back on the road as soon as possible.',
                },
                {
                    text: 'As well as having a sophisticated, bespoke case management system, our highly experienced in-house claims team also have extensive experience in recovering all forms of uninsured loss and will support you from the very start of your claim. You can also report and track your claims online in real time.',
                },
                {
                    titleDes: 'Peace of Mind',
                },
                {
                    text: 'Insurance has always been about peace of mind; the trouble can come when what you thought would be covered, isn’t. Could you afford to lose days of work through injury or pay for car hire until yours was fixed? The good news is it’s never too late to purchase Legal Expense Insurance or pursue Uninsured Loss Recovery and [United Legal Assistance](/) is here 24 hours a day to start recouping your expenses and help take the weight off your shoulders.',
                },
            ]
        }

    ],
    "team-ben": [
        {
            SEOTitle: `Meet The ULA Team: Ben Navin - ULA Blog`,
            SEODescription: `We wanted to give you an insight into the people behind United Legal, first up is our Claims Handler - Ben Navin.`,
            title: 'Meet the ULA Team: Ben Navin',
            alignment: 'left',
            inline: true,
            image: team_ben,
            description: [
                {
                    text: 'This is the first article in our new blog series; Meet the ULA Team. We wanted to give you an insight into the people behind United Legal, first up is our Claims Handler – Ben Navin.'
                },
                {
                    boldTitle: '**1. Tell us about yourself and what you do at United Legal Assistance?**',
                },
                {
                    text: 'Hi, I’m Ben my role at United Legal is to handle cases from start to finish and recover the client’s uninsured losses. These may be the cost of repairs to the customer’s vehicle, an excess (if the client uses their policy for repairs in non-fault cases) and on occasion hire charges.'
                },
                {
                    text: 'I also appoint hire car companies for clients whose vehicles have been deemed unroadworthy following an accident, and they are unable to arrange a replacement themselves. I aim to make the process of a claim as simple and stress-free as possible for clients and provide the best service possible.'
                },
                {
                    boldTitle: '**2. What has been your top moment at ULA so far?**',
                },
                {
                    text: 'My top moment with ULA so far has been when I managed to claim a commercial client’s losses back in full. This particular application was tough to handle as the third party insurers were adamant that their client was not at fault for the accident. I spent a lot of time looking through various pieces of evidence regarding the case and managed to obtain an admission of liability and the losses in full. The client was extremely happy with the way this claim was handled.'
                },
                {
                    boldTitle: '**3. How long have you worked in the Insurance Industry?**',
                },
                {
                    text: 'I have worked in the insurance industry for just over two years having started with ULA in June 2014.'
                },
                {
                    boldTitle: '**4. What is your favourite thing to do outside of work?**',
                },
                {
                    text: 'Outside of work, I enjoy socialising with friends and watching various sporting events. I play darts for a local pub team and compete in both men’s league and a mixed league. I am a season ticket holder at Everton FC and go to every home game with my older brother who is also a season ticket holder.'
                },
                {
                    boldTitle: '**5. Tell us something that nobody in the office knows about you?**',
                },
                {
                    text: 'I didn’t learn how to swim until I was 14 and taught myself in Turkey while on holiday with my family.'
                },
                {
                    boldTitle: '**6. What is your dream car?**',
                },
                {
                    text: 'A Black Lamborghini Aventador'
                },
                {
                    boldTitle: '**7. If you were to host a party, which 3 celebrities would you invite and why?**',
                },
                {
                    text: 'Duncan Ferguson – He has always been a hero of mine from watching him play for Everton while I was growing up.'
                },
                {
                    text: 'Alan Sugar – I have always wanted to talk to him about his business sense and how he came up with the ideas that made him so successful. I could also possibly pitch him some ideas and convince him to invest in me.'
                },
                {
                    text: 'Russell Brand – I find his films and show really funny, and I think it would be interesting to discuss with him his political views. I have also read his autobiography so it would be interesting to talk to him about how he turned his life around.'
                },
            ]
        }

    ],
    "telematics": [
        {
            SEOTitle: `What is Telematics Insurance, and what’s the advantages? | ULA Blog`,
            SEODescription: `With over half-a-million people in the UK having telematics insurance, we answer the common question; what is telematics insurance in this article.`,
            title: 'What is Telematics Insurance, and what’s the advantages?',
            alignment: 'left',
            inline: false,
            image: telematics,
            description: [
                {
                    text: 'Today in the UK, there is over half-a-million motorists that have a telematics box installed in their vehicle, of which is part of a telematics insurance policy. A relatively new and very popular type of insurance that is changing the way consumers think about insuring their vehicle.'
                },
                {
                    text: 'According to [The British Insurance Broker’s Association (BIBA)](https://www.biba.org.uk/), they have seen a rise in this type of policy over the last few years by a whopping 40%.'
                },
                {
                    text: 'But, what is telematics insurance, and what are the advantages?'
                },
                {
                    text: 'Well, telematics insurance, black-box insurance or even pay-as-you-drive insurance is a way for insurers to analyse the way you drive. When fitted in your vehicle, the telematics box will record things like speed, braking patterns and distance travelled, all with the aim to calculate how safe you’re driving.'
                },
                {
                    text: 'This type of policy allows insurers to calculate risk on the kind of driver you are and adjust the price of your policy accordingly. This different compared to a stand-alone insurance policy, as the price you pay is based on assumptions and industry trends. Some telematics insurance companies even reward safe drivers instantly, meaning no claims discounts will be applied within a couple of months.'
                },
                {
                    text: 'So, what’s the advantages?'
                },
                {
                    titleDes: 'If you drive safe, you get rewarded',
                },
                {
                    text: 'As we mentioned above, a telematics box can measure different areas of your driving. If you keep to the speed limit, you aren’t too heavy on the brakes and travel at quieter times of the day, be prepared to see a reduced premium.'
                },
                {
                    titleDes: 'Black-box insurance is better for society',
                },
                {
                    text: 'With around five deaths on average a day on the roads in the UK, promoting safe driving through black-box insurance with help decrease traffic accidents.'
                },
                {
                    titleDes: 'Ward off thieves',
                },
                {
                    text: 'Telematics boxes are GPS enabled, meaning your vehicle can be tracked if reported stolen. Some telematics boxes have the capability to disengage the ignition.'
                },
                {
                    titleDes: 'Helps with claims management',
                },
                {
                    text: 'With a vast amount of information available from a telematics box, this will speed up a claims process by providing insurers with detailed analysis.'
                },
                {
                    text: 'Telematics insurance is an excellent option for young and new drivers, but it designed to reward the good drivers. However, this type of policy does come with some disadvantages…'
                },
                {
                    titleDes: 'Telematics box cannot tell who is driving',
                },
                {
                    text: 'Even if you are the safest driver in the world, a telematics box cannot detect if somebody else is driving the car. So, if you have a secondary driver on your policy who has a few bad habits, this will affect your black-box data.'
                },
                {
                    titleDes: 'The black-box is not free',
                },
                {
                    text: 'The devices installed kind be pretty costly, even if your insurer fits the black-box for free, you will more than likely be charged in a different way, whether an add-on or via the premium you pay.'
                },
                {
                    text: 'We hope this article proves to be useful when selecting your next insurance policy.'
                },
                {
                    text: 'United Legal Assistance are market leaders in Claims Management and Legal Expense insurance, we would be more than happy to answer any questions you have about Black-Box insurance!'
                },
            ]
        }

    ],
    "raise": [
        {
            SEOTitle: `ULA Raise £600 for The Guide Dogs for the Blind Charity! - United Legal Assistance`,
            SEODescription: `On Friday 12th August, our client DCJ Group organised a golf day for The Guide Dogs for the blind charity, which raised a total of £3,800.`,
            title: 'ULA Raise £600 for The Guide Dogs for the Blind Charity!',
            alignment: 'left',
            inline: true,
            image: raise,
            description: [
                {
                    text: 'On Friday 12th August, our client [DCJ Group](https://www.dcj-insurance.co.uk/) organised a golf day for The Guide Dogs for the blind charity, which raised a total of £3,800.'
                },
                {
                    text: 'We decided to help them out and donated our [Manchester United Experience](/manchester-united-hospitality) for four people as an auction prize. This fantastic day out managed to raise a total of £600 which contributed to the final total of £3,800.'
                },
            ]
        }

    ],
    "support": [
        {
            SEOTitle: `United Legal Assistance Support Fraud Prevention`,
            SEODescription: `Today United Legal Assistance confirmed they would support fraud prevention by working with fraud prevention company MRB Consulting Ltd.`,
            title: 'United Legal Assistance to Support Fraud Prevention',
            alignment: 'left',
            inline: true,
            image: support,
            description: [
                {
                    text: 'Personal Injury and motor fraud in the UK is a big problem. United Legal are pleased to be working with a market leading fraud prevention consultancy firm to assist the firm with processes and procedures to prevent claims and motor fraud. MRB Consulting Ltd have 18 years experience in fraud prevention and a huge amount of industry knowledge and contacts, and have teamed up with United Legal aiming to be the market leading CMC in the UK.'
                },
                {
                    text: 'MRB Consulting Ltd will be assisting United Legal maximise their own data usage and flagging mechanisms to prevent claims and vehicle hire fraud, via automated processes and staff training. We believe that through this joint initiative we will further streamline the business and ensure that we protect genuine customers.'
                },
                {
                    text: 'Michael Black, MD at MRB Consulting Ltd: “I am delighted to be working with United Legal. Many CMCs in the UK can talk the talk, but lots do not walk the walk. Through this project I am confident that United Legal will be one of the most efficient and well-equipped firms in preventing and managing fraud in this arena.”'
                },
                {
                    text: 'Gavin Hughes, Director, United Legal: “Although we have only been working with MRB Consulting Ltd for a short time, we instantly knew we had to tighten our existing processes to further protect our clients and business from fraud. We will be rolling out a number of measures to bolster our product offering, and are excited to be involved in this joint project. We hope the relationship sends out a clear message to the market and that United Legal aim to be one of the largest CMCs in the UK.”'
                },
            ]

        }

    ],
    "insurances": [
        {
            SEOTitle: `Fully Comprehensive Insurance vs Third Party - United Legal Assistance`,
            SEODescription: `When you drive, you have to have appropriate car insurance – the minimum cover being third party.`,
            title: 'Fully Comprehensive Insurance vs Third Party',
            alignment: 'left',
            inline: false,
            image: insurances,
            description: [
                {
                    text: 'When you drive, you have to have appropriate car insurance – the minimum cover being third party. Insurance companies offer various levels of cover and when you buy car insurance, you usually choose between fully comprehensive or third party, fire and theft. Here we’ll explain the difference between these types of cover.'
                },
                {
                    titleDes: 'Third Party and Third Party, Fire & Theft',
                },
                {
                    text: 'If you take out third party only, it will insure you against damage to other vehicles, people or property that you might harm in an accident that was your fault. It doesn’t cover your own car, or you. So if you were to crash into the side of someone’s house, your insurance would cover the repair of the house, but not your car.'
                },
                {
                    text: 'As you’d expect, third party, fire and theft is as above but also protects your car if it was damaged due to fire (either accidental or intentional) or theft, or stolen.'
                },
                {
                    text: 'In some cases, claims may be muddied by circumstances – for example, if your car window is smashed in an attempt to steal from your car, but nothing is actually taken, your claim may end up being a rejected as technically the theft never took place and you’d have to sort the broken window yourself.'
                },
                {
                    titleDes: 'Fully Comprehensive Insurance',
                },
                {
                    text: 'This covers everything we’ve already mentioned and then additionally, damage to you and your car. Usually this will be more expensive than basic cover because of the extra benefits of having it, like covering you and your car and then extra things like courtesy car or legal protection if that’s included in the policy.'
                },
                {
                    text: 'So, if you were in an accident that was your fault, your insurance would cover any third parties involved (and their property) but then also any repairs to your car including the value of the car if it’s written off.'
                },
                {
                    titleDes: 'Fully Comp isn’t a One Stop Cover-All',
                },
                {
                    text: 'Although the better option of the two in terms of cover, fully comprehensive insurance doesn’t necessarily cover everything. There are numerous add-ons from policy to policy including courtesy cars and legal expense insurance. These can push the price of a policy up but it’s always worth looking into what a policy includes and how much it would be to add these extras on.'
                },
                {
                    titleDes: 'Legal Expense Cover as an Add-On',
                },
                {
                    text: 'We talked about the need for legal expense cover [URL for previous article here] but it’s worth mentioning again, now we’re covering types of insurance policy. If something does happen to you or your car and you need to go to court to defend yourself, or claim back out of pocket expenses then that’s where legal expense insurance kicks in. For the sake of £20 – £30 it’s usually worth adding onto your policy if it’s not already included. Weigh up whether you could afford the costs of going to court yourself if something happened.'
                },
                {
                    titleDes: 'Which Type of Cover is Right for Me?',
                },
                {
                    text: 'Third party, fire and theft is usually a cheaper option for new drivers or those who drive relatively inexpensive cars that they could afford to replace themselves, should the worst happen. Bizarrely, and contrary to popular belief, this type of cover isn’t always cheaper than fully comp, so it’s always worth checking. This is due to many high-risk drivers opting for third party cover to save money, resulting in a statistically higher number of claims, making it a more expensive type of insurance.'
                },
                {
                    text: 'Fully comp is therefore usually more expensive but covers you for more eventualities and includes both you and your vehicle in that.'
                },
                {
                    text: 'Here are the key differences:'
                },
                {
                    boldTitle: '***TPFT Covers:***',
                },
                {
                    list: 'Your car being stolen'
                },
                {
                    list: 'Fire damage to your car'
                },
                {
                    list: 'Damage caused to a third party, their vehicle and/or their property including repairs and medical treatment costs'
                },
                {
                    list: 'Legal claims made against you as the result of an accident'
                },
                {
                    boldTitle: '***Fully Comp covers (all of the above and):***'
                },
                {
                    list: 'Repairs to your car'
                },
                {
                    list: 'The market value of your car if it’s written off'
                },
                {
                    list: 'Your medical expenses'
                },
                {
                    titleDes: 'Final thoughts…',
                },
                {
                    text: 'Always consider what you can afford and what you may need. It’s easy to go mad with insurance and add everything on for peace of mind but think carefully about the kind of journeys you make and whether you’d need things like a courtesy car. Fully comp (with considered extras) is usually the best bet for most drivers for its level of cover, peace of mind and in many cases, even cost.'
                },
            ]
        }

    ],
    "top-10-2015": [
        {
            SEOTitle: `Our Top 10 Reliable Cars of 2015 - United Legal Assistance`,
            SEODescription: `When you are thinking of buying a new car, the one thing you’re unlikely to compromise on is reliability and safety.`,
            title: 'Our Top 10 Reliable Cars of 2015',
            alignment: 'left',
            inline: false,
            image: top_10_2015,
            description: [
                {
                    text: 'When you are thinking of buying a new car, the one thing you’re unlikely to compromise on is reliability and safety. It doesn’t matter if you’re buying a showy, sporty number or a good solid family car, you want to know it will get you from A to B for years to come without becoming a money pit.'
                },
                {
                    text: 'Cars today claim to have never been more reliable, we have all seen and read the advertisements that make all sorts of statements and choosing the car that is right for you and one that will deliver on what you need has never been more confusing. Turn on the TV for half an hour and you are guaranteed to see a bewildering set of car choices.'
                },
                {
                    text: 'According to What Car, magazine Japanese brands have dominated the market this year in terms of reliability and in particular, Honda has stood out as having consistently low rates of failure.  When you consider that we’re still coming out of a recession, this is such an important consideration. There’s no guarantee that things will be perfect with any car, so if the worst does happen, you also want to know that fixing your car isn’t going to cost a small fortune. The popularity of Japanese models mean that they are well catered for by garages and, should the worst happen, parts are easily obtainable. What Car, site the Honda Jazz and Mitsubishi Lancer has having the lowers repair costs, comparing them to an Audi RS6 which had a repair bill averaging over £1000.'
                },
                {
                    text: 'Based on what the top sites are saying, here’s our roundup of what we think are the 10 most reliable cars so far in 2015:'
                },
                {
                    titleDes: 'Honda Jazz',
                },
                {
                    image: honda_green,
                    text: 'Hugely fuel efficient which is a definite consideration when buying your next car, the Honda Jazz is also pegged as incredibly reliable. In 2009 the Jazz earned a five star rating when tested by Euro NCAP.'
                },
                {
                    titleDes: 'Toyota Yaris'
                },
                {
                    image: toyota_red,
                    text: 'Although its popularity has dropped a little lately, the Yaris Hybrid has low running costs and few reliability issues. Being a hybrid also means that should you go down this route you are also helping the environment as the propulsion combines a traditional car engine with electric motor assistance.'
                },
                {
                    titleDes: "Hyundai i10"
                },
                {
                    image: hyundai_white,
                    text: '[Hyundai’s website](https://www.hyundai.com/uk/en/models/new-i10.html) brags that the i10 has intelligent engineering and so high levels of safety, including anti-lock brake technology with braking assistance and six airbags as standard. We aren’t quite sure what ‘intelligent engineering’ is and are pretty sure most engineers would claim some sort of intelligence but in the case of the i10 the results are impressive.'
                },
                {
                    titleDes: 'Nissan Leaf'
                },
                {
                    image: nissan_white,
                    text: 'With more and more charging points being added around the country, the Leaf is now hugely popular, claiming the title of bestselling electric car in the world. According to [Car Buyer](https://www.carbuyer.co.uk/best-cars/114046/top-10-most-reliable-cars), it also placed eight in an overall satisfaction survey. Once again this is a car that positively hugs the environment being a pure electric vehicle.'
                },
                {
                    titleDes: 'SEAT Leon'
                },
                {
                    image: seat_white,
                    text: 'With stability control, tyre pressure monitoring and a multitude of airbags, SEAT have certainly invested in safety with the Leon. The Leon and its variants has been a popular choice since it was first produced back in 1998.'
                },
                {
                    titleDes: 'Kia Picanto'
                },
                {
                    image: kia_red,
                    text: 'This small car is not to be underestimated. It’s based on the Hyundai i10 (just above it on our list) and comes with Kia’s whopping seven year warranty. Once again, like the i10 it has been produced by those ‘intelligent engineers’ so it must be a hot contender for all round reliability.'
                },
                {
                    titleDes: 'Lexus GX 460'
                },
                {
                    image: lexus_grey,
                    text: 'Designed primarily for off-road driving, the GX 460 is solidly built with four wheel drive and high-tech adaptive suspension. Truth be told most are probably used on the road but having engineering that is designed for rough terrain can only be a plus, even if you are only going to the shops and back.'
                },
                {
                    titleDes: 'Suzuki Swift'
                },
                {
                    image: suzuki_white,
                    text: 'Another car which received the coveted 5 star Euro NCAP rating and 94% for adult occupant protection, proving that investment in safety and reliability really does count when it comes to producing successful cars. The Swift has a fifteen year record that is hard to beat.'
                },
                {
                    titleDes: 'Kia Sportage'
                },
                {
                    image: kia_white,
                    text: 'Available in two or four wheel drive, the Sportage received 5 stars at the 2010 Euro NCAP launch. It also has the rather nifty ‘roll-over’ detector, automatically deploying side airbags and seat-belt pre-tensioners for extra security and safety. The Sportage fits neatly into that ‘I want four wheel drive, but I mainly pick the kids up from school’ bracket.'
                },
                {
                    titleDes: 'Mazda 2'
                },
                {
                    image: mazda_blue,
                    text: 'With its sporty look inside and out combined with advanced safety features like automatic emergency breaking and a lane-departure warning system, the Mazda2 is a popular choice. As the years roll by and the cars we drive evolve, the use of smart technology like that in the Mazda2 becomes more and more prevalent so we can expect to see more and more safety devices like those spearheaded by Mazda.'
                },
                {
                    titleDes: 'Closing thoughts…'
                },
                {
                    text: 'Reliability and safety is so important when choosing a car, you can’t, won’t and shouldn’t compromise. As with all ‘top tens’, this is just our opinion of some of the best cars out there for reliability and safety but we hope it gives you some fresh ideas of where to look and some good sources to do your research before you buy and that you find the car that’s perfect for you. Don’t forget, here at [United Legal Assistance](/) we provide a variety of products, including legal expenses insurance, so get in touch today!'
                }
            ]
        }
    ],
    "car-accident": [
        {
            SEOTitle: `What should I do after a car accident? - United Legal Assistance`,
            SEODescription: `Stop the car when and where it’s safe to do so: Failing to do this can incur a fine of up to £5,000 as well as 5-10 penalty points on your licence.`,
            title: 'What should I do after a car accident?',
            alignment: 'left',
            inline: false,
            image: car_accident,
            description: [
                {
                    text: 'Being involved in a car accident, even just a bump, can be a stressful experience. In this blog, we’ll give you the information you need to help you decide what to do next.'
                },
                {
                    titleDes: 'Immediately after a Car Accident:',
                },
                {
                    boldTitle: '***Stop the car when and where it’s safe to do so:***',
                },
                {
                    text: 'Failing to do this can incur a fine of up to **£5,000** as well as **5-10 penalty points** on your licence. It’s also possible to be banned from driving or even imprisoned depending on the seriousness of the accident, so even if you’re in shock it’s important to stay calm and stop. Switch off your car engine and turn on hazard lights to warn other drivers there’s a problem.'
                },
                {
                    boldTitle: '***Call emergency services if anyone is injured or cars are in an unsafe place:***',
                },
                {
                    text: 'Call 999 if anyone is seriously hurt or cars are blocking the road. Use 101 if everyone is fine and there is no immediate danger but still significant damage. With injury or large amounts of damage, you also need to report the accident to the police in person within 24 hours and produce your insurance certificate within 7 days.'
                },
                {
                    boldTitle: '***Exchange details with the other party or parties involved in the accident:***',
                },
                {
                    text: 'Accidents will vary and it’s not always possible to see immediately, who was at fault. Avoid saying sorry or apologising at the scene as it could count against you later on and look like an admission of blame. Regardless of who’s to blame, you should take details from the other driver(s):'
                },
                {
                    list: 'Car registrations, makes, models and colours.'
                },
                {
                    list: 'Names, contact details and insurers of drivers.'
                },
                {
                    list: 'Extra notes: scene location, time and date of the accident, how many passengers and anything you can remember from the accident while it’s fresh in your mind.'
                },
                {
                    list: 'It’s worth taking pictures of the cars in situation and also any obvious damage if possible and safe to do so.'
                },
                {
                    text: 'If the others refuse to give details or drive off, try to note down at least their make, model and registration of car so that they can be traced later. Sometimes the heat of the moment can get to drivers; if you feel threatened or someone is being abusive, call the police.'
                },
                {
                    text: 'If there’s no-one at the scene, for example if you hit a parked car, then wait until the person comes back or leave your details on their windscreen; still making a note of their car details.'
                },
                {
                    text: 'Once you’ve done this, you need to call your insurer and let them know about the accident, passing on any other details you collected at the scene.'
                },
                {
                    titleDes: 'Witnesses'
                },
                {
                    text: 'If someone witnesses the accident, it’s important to obtain their details too and ask them to note down what they saw while it’s still easy to remember accurately. If it’s not clear from damage who was at fault for the accident, the witness statement could be integral for proving a case.'
                },
                {
                    text: 'If the police are present, it’s wise to make a note of the officer’s name, station and badge number so that you can contact them about police reports if needed, later.'
                },
                {
                    titleDes: 'Telling your Insurer'
                },
                {
                    text: 'It can be tempting to omit certain facts or slightly change a story so that it doesn’t reflect badly on us, but if your insurance company finds evidence to the contrary then it can have serious consequences, so be sure to only give facts and explain everything clearly. As well as passing on details of others who were involved, be sure to mention any photographs, police reports or witnesses.'
                },
                {
                    titleDes: 'Keep Track of Any Aftercare'
                },
                {
                    text: 'If you need medical treatment for injury relating to the crash, be sure to keep records of all visits to the doctor/hospital and receipts for prescriptions or medication. Should you need to rent a car so that you can still work, but your insurer doesn’t cover that, then keep paperwork for this too, you could claim the costs back if another driver is found at fault. Only claim for things you have a valid need for – an insurance company isn’t going to pay for a Mercedes hire if your Smart Car is being repaired.'
                },
                {
                    titleDes: 'Legal Assistance'
                },
                {
                    text: 'Sometimes legal assistance is covered by your insurer, but if not, consider having someone to take the strain from chasing all of this up yourself. An experienced company can help you with recovery, recouping any out of pocket expenses and even compensation where applicable. If you’re at fault, a qualified and skilled firm can help you better defend yourself and support you in minimising costs. After all that’s what we specialise in, so why not get in touch with our team today?'
                },
                {
                    titleDes: 'Closing points'
                },
                {
                    text: 'We hope you’re never in the unfortunate position of being in a road traffic accident, but if you are, our short but comprehensive guide will help you to stay calm and take you through what to do step by step. Remember our main points:'
                },
                {
                    list: 'Stop the car when it’s safe to do so'
                },
                {
                    list: 'Call the emergency services if someone is injured, a road is blocked or there is serious damage'
                },
                {
                    list: 'Report any injuries in person at the police station and bring your certificate of insurance'
                },
                {
                    list: 'Exchange details with other parties'
                },
                {
                    list: 'Take any witnesses contact details'
                },
                {
                    list: 'Take pictures and make notes while it’s still fresh in your mind'
                },
                {
                    list: 'Inform your insurer'
                },
                {
                    list: 'Keep receipts and paperwork for out of pocket expenses or medical care'
                },
                {
                    list: 'Consider legal assistance from a knowledgeable, reputable firm such as United Legal Assistance.'
                },
                {
                    text: 'Stick to this guide and we’re confident that you’ll make it through a difficult time with as little worry, inconvenience and expense as possible. If you have any questions then our team would be more than happy to answer them for you. [Just get in touch](/contact-us), we’re here to help!'
                }
            ]
        }

    ],
    "welcome": [
        {
            SEOTitle: `Welcome to United Legal Assistance - United Legal Assistance`,
            SEODescription: `Welcome to United Legal Assistance!`,
            title: 'Welcome to United Legal Assistance',
            alignment: 'left',
            youtubeUrl: 'https://www.youtube.com/embed/MeDiactgh1Q'
        }

    ],

}