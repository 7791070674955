import React from "react"
//import 'uikit/dist/css/uikit.css'
import CustomMarkdown from "../../utils/customMarkdown"
import "./paragraph-article.scss"

const Paragraph = ({ props, origin }) => {

    const paragraph = props[origin];

    return (
        paragraph ? paragraph.map((item, index) => {
            return (
                <div className="paragraph-container">
                    <div className="paragraph-content">
                        {
                            item.title ?
                                <div className="paragraph-header">
                                    <h2>{item.title}</h2>
                                    {/* {
                                        item.subtitle ? <p>{item.subtitle}</p> : ""
                                    } */}
                                </div>
                                : ""
                        }
                        {
                            item.image ?
                                <img src={item.image} alt="article-image" className="article-image" style={{ width: "1200px" }} />
                                : ""
                        }

                        {
                            item.inline == true ?
                                <div className="paragraph-description" style={{ textAlign: item.alignment }}>
                                    {
                                        item.description.map((itemDes, index) => {
                                            return (
                                                <div>
                                                    {itemDes.image ?
                                                        <img src={itemDes.image} alt="article-img" className="description-image" />
                                                        : ""}
                                                    {itemDes.boldTitle || itemDes.text ?
                                                        <CustomMarkdown children={itemDes.boldTitle ? itemDes.boldTitle : itemDes.text ? itemDes.text : ""} className={itemDes.highlight ? "custom-text-color" : "custom-text"} /> : ""}
                                                    {itemDes.list ? <ul><li>{itemDes.list}</li></ul> : ""}
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                :
                                item.description ?
                                    <div className="paragraph-description" style={{ textAlign: item.alignment }}>
                                        {
                                            item.description.map((itemDes, index) => {
                                                return (
                                                    <div>
                                                        {itemDes.titleDes ?
                                                            <h3>{itemDes.titleDes}</h3> : ""}
                                                        {
                                                            itemDes.image ?
                                                                <img src={itemDes.image} alt="article-img" className="description-image" />
                                                                : ""
                                                        }
                                                        {itemDes.boldTitle || itemDes.text ?
                                                            <CustomMarkdown children={itemDes.boldTitle ? itemDes.boldTitle : itemDes.text ? itemDes.text : ""} className={itemDes.highlight ? "custom-text-color" : "custom-text"} /> : ""}
                                                        {itemDes.list ? <ul><li>{itemDes.list}</li></ul> : ""}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : ""
                        }
                        {
                            item.youtubeUrl ?
                                <div className="youtube-video" style={{ justifyContent: item.alignment }}><iframe
                                    src={`${item.youtubeUrl}`}
                                    width="900"
                                    height="500"
                                    style={{ objectFit: 'cover !important' }}
                                />
                                </div>
                                : ""
                        }
                    </div>
                </div>
            )
        }) : ""


    )

}

export default Paragraph