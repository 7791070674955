import React from 'react'
import loadable from '@loadable/component'
import 'uikit/dist/css/uikit.css'
import Paragraph from '../components/paragraph-article/paragraph-article';
import { information } from "../utils/blogPostsInformation"

const Layout = loadable(() => import('../layouts'))


const Blog = ({ pageContext, props }) => {

    const { origin } = pageContext

    const seoTitle = information[origin].map(article => article.SEOTitle)

    const seoDescription = information[origin].map(article => article.SEODescription)

    return (
        <Layout
            SEOTitle={seoTitle}
            SEODescription={seoDescription}
            footerDisclaimer={""}>

            <Paragraph props={information} origin={origin} />

        </Layout>
    )
}

export default Blog
